import AWS from "aws-sdk";

const s3DownloadFile = (key) => {
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID_AWS,
    secretAccessKey: process.env.REACT_APP_ACCESSKEYSECRET_AWS,
  });
  const s3 = new AWS.S3();
  const presignedURL = s3.getSignedUrl("getObject", {
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: key,
    Expires: 5 * 60, //time to expire in seconds - 5 min,
  });
  return presignedURL;
};

export default s3DownloadFile;
