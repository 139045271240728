const GetAttendentOrdestination = (key) => {
  let AttendentOrdestination = key;
  switch (AttendentOrdestination) {
    case "1001":
      AttendentOrdestination = "Mateus Medeiros (Ramal 1001)";
      break;
    case "1002":
      AttendentOrdestination = "Mateus Oliveira (Ramal 1002)";
      break;
    case "1003":
      AttendentOrdestination = "Marcos Queiroz (Ramal 1003)";
      break;
    case "1004":
      AttendentOrdestination = "Marcos Lima (Ramal 1004)";
      break;
    case "1005":
      AttendentOrdestination = "Weverton (Ramal 1005)";
      break;
    case "1006":
      AttendentOrdestination = "Gilbert (Ramal 1006)";
      break;
    case "1007":
      AttendentOrdestination = "Guilherme Lobão (Ramal 1007)";
      break;

    default:
      break;
  }
  return AttendentOrdestination;
};

export default GetAttendentOrdestination;
