const IsAuth = () => {
  const rawHash = localStorage.getItem("hash");
  if (rawHash === process.env.REACT_APP_ACCESSKEY) {
    return true;
  } else {
    return false;
  }
};

export default IsAuth;
