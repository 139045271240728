import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import Logo from "../Assets/Images/Logo.png";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import s3DownloadFile from "../Services/s3DownloadFile";
import LogoutIcon from "@mui/icons-material/Logout";
import MUIDataTable from "mui-datatables";
import handleLogout from "../Services/doLogout";
import { Box, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "red !important",
          padding: "0px !important",
        },
      },
    },
  });

const handleDownload = (value) => {
  window.open(s3DownloadFile(value));
};
function MUIDataTableComponent({ data }) {
  const options = {
    selectableRows: "none",
    filter: false,
    textLabels: {
      body: {
        noMatch: "Desculpe, não foram localizado(s) registro(s).",
        toolTip: "Filtrar",
        columnHeaderTooltip: (column) => `Filtrar Por ${column.label}`,
      },
      pagination: {
        next: "Próxima Págin",
        previous: "Página Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Visualizar Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Limpar",
      },
      viewColumns: {
        title: "Exibir Colunas",
        titleAria: "Exibir/Ocultar Colunas da Tabela",
      },
      selectedRows: {
        text: "Linha(s) Selecionada",
        delete: "Deletar",
        deleteAria: "Deletar linha(s) selecionadas",
      },
    },
  };
  const columns = [
    {
      name: "key",
      label: "Download",
      options: {
        filter: true,
        customBodyRender: (value) => {
          return (
            <IconButton aria-label="save" onClick={() => handleDownload(value)}>
              <SaveIcon style={{ fill: "#000000" }} fontSize="smal" />
            </IconButton>
          );
        },
      },
    },
    {
      name: "Attendant",
      label: "Origem",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "destination",
      label: "Destino",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "key",
      label: "Nome do Arquivo",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastModified",
      label: "Data",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "callHour",
      label: "Hora da Ligação",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "hour",
      label: "Hora no Server",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                maxWidth: "10%",
              }}
            >
              <div style={{ padding: "30px" }}>
                <img src={Logo} alt="Logo getinfo Sistemas" />
              </div>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Tooltip title="Sair">
                <IconButton onClick={handleLogout}>
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Box>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          sx={{ backgroundColor: "#525879" }}
          title={`TRT AUDITORIA`}
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </>
  );
}

export default React.memo(MUIDataTableComponent);
