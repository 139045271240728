import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import LoggedPage from './components/LoggedPage';
import reportWebVitals from './reportWebVitals';
import SharedState from './Hooks/SharedState';

ReactDOM.render(
  <React.StrictMode>
    <SharedState>
      <Router>
        <Routes>
          <Route path='/*' element={<App />} />
          <Route path='/audith' element={<LoggedPage />} />
        </Routes>
      </Router>
    </SharedState>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
