import GetAttendentOrdestination from './GetAttendentOrdestination';

const formatAwsReturn = (rawData) => {
  const arr = rawData?.map((key) => {
    // Verifica se o modelo de dados é o padrão esperado
    if (key?.Key && key?.LastModified) {
      const date = new Date(key.LastModified);
      const fullHour =
        date.getHours() +
        ':' +
        (date.getMinutes() < 10 ? '0' : '') +
        date.getMinutes() +
        ':' +
        date.getSeconds();

      const attendant = key?.Key?.includes('~call~')
        ? key.Key.split('~call~')[1]?.split('~')[0]
        : null;
      const destination = key?.Key?.includes('~call~')
        ? key.Key.split('~call~')[1]?.split('~')[1]
        : null;
      const rawRecHour = key?.Key?.includes('T')
        ? key.Key.split('T')[1].split('~')[0]
        : null;
      const recHour =
        rawRecHour?.substring(0, 2) +
        ':' +
        rawRecHour?.substring(2, 4) +
        ':' +
        rawRecHour?.substring(4, 6);

      return {
        Attendant: GetAttendentOrdestination(attendant),
        destination: GetAttendentOrdestination(destination),
        key: key?.Key,
        callHour: recHour,
        lastModified: new Date(key.LastModified).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        }),
        hour: fullHour,
      };
    } else {
      // Se o modelo de dados não for o padrão esperado, retorna null
      return null;
    }
  });

  // Filtra para remover os registros que são null (extra)
  const filteredArr = arr.filter((item) => item !== null);

  return filteredArr;
};

export default formatAwsReturn;
