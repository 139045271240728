import React from "react";

const MyContext = React.createContext();
export default function SharedState({ children }) {
  const [rawData, setRawData] = React.useState({
    userOrPassIsWrong: false,
    s3IsLoading: true,
    s3Data: [],
  });
  return (
    <MyContext.Provider value={{ rawData, setRawData }}>
      {children}
    </MyContext.Provider>
  );
}
export function useSharedState() {
  const context = React.useContext(MyContext);
  const { rawData, setRawData } = context;
  return { rawData, setRawData };
}
