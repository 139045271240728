import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import awsRetrieveData from "../Services/awsRetrieve";
import MUIDataTableComponent from "./MUIDataTable";
import SignIn from "./SignIn";
import IsAuth from "../Services/IsAuth";

const LoggedPage = () => {
  const [rawData, setData] = React.useState({
    isLoading: true,
    data: undefined,
  });

  React.useEffect(() => {
    awsRetrieveData().then((awsData) => {
      setData((prevState) => ({
        ...prevState,
        data: awsData,
        isLoading: false,
      }));
    });
    return () => {
      let controller = new AbortController();
      controller.abort();
    };
  }, []);

  return rawData.isLoading ? (
    <div
      style={{
        width: "90%",
        position: "fixed" /* or absolute */,
        top: "40%",
      }}
    >
      <center>
        <CircularProgress />
      </center>
    </div>
  ) : IsAuth() ? (
    <MUIDataTableComponent data={rawData.data} />
  ) : (
    <SignIn />
  );
};

export default React.memo(LoggedPage);
