import AWS from 'aws-sdk';
import formatAwsReturn from './FormatData';

const awsRetrieveData = () => {
  return new Promise(async (resolve, reject) => {
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_ACCESSKEYID_AWS,
      secretAccessKey: process.env.REACT_APP_ACCESSKEYSECRET_AWS,
    });
    const s3 = new AWS.S3();
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Prefix: process.env.REACT_APP_AWS_PREFIX,
      MaxKeys: 1000,
    };
    const rawData = [];
    let isTruncated = true;
    let marker;
    while (isTruncated) {
      if (marker) params.Marker = marker;
      const response = await s3.listObjects(params).promise();
      response.Contents.forEach((item) => {
        rawData.push(item);
      });
      isTruncated = response.IsTruncated;
      if (isTruncated) {
        marker = response.Contents.slice(-1)[0].Key;
      }
    }
    resolve(
      formatAwsReturn(
        rawData.sort((a, b) => (a.LastModified > b.LastModified ? -1 : 1))
      )
    );
  });
};

export default awsRetrieveData;
