import SignIn from "./components/SignIn";

function App() {
  return (
    <div className="App">
      <SignIn />
    </div>
  );
}
export default App;
